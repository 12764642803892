import { forwardRef, type ElementRef, type ComponentPropsWithoutRef } from 'react'
import { ModalOverlay, Modal, Dialog as DialogPrimitive, DialogTrigger, HeadingContext, useContextProps } from 'react-aria-components'
import { cn } from '#app/utils/tailwind-merge.ts'
import { H2 } from '../typography/h2.tsx'

export interface DialogProps extends Omit<ComponentPropsWithoutRef<typeof DialogPrimitive>, 'role'> {
	alert?: boolean
	side?: 'center' | 'left' | 'right' | 'top' | 'bottom'
}

/**
 * Dialog component.
 *
 * @component
 * @example
 * ```jsx
 * <DialogTrigger>
 * 	<Button>Create account</Button>
 * 	<Dialog>
 * 		{({ close }) => (
 * 			<>
 * 				<DialogTitle>Create account</DialogTitle>
 * 				<Flex justify="end" gap="2" className="mt-6">
 * 					<Button variant="secondary" onPress={close}>Cancel</Button>
 * 					<Button variant="primary" onPress={close}>Create</Button>
 * 				</Flex>
 * 			</>
 * 		)}
 * 	</Dialog>
 * </DialogTrigger>
 * ```
 *
 * @param {boolean} [alert=false] - Whether the dialog is an alert dialog.
 * @param {string} [side='center'] - Side of the screen to anchor the dialog to. Can be `center` (default), `left`, `right`, `top`, or `bottom`.
 * @param {ReactNode | (opts: DialogRenderProps) => ReactNode} [children] - Children of the dialog. A function may be provided to access a function to close the dialog.
 * @param {string} [className] - Additional CSS class names.
 * @param {React.HTMLAttributes<HTMLDivElement>} [props] - Additional HTML attributes.
 * @returns {JSX.Element} The Dialog component.
 */
const Dialog = forwardRef<ElementRef<typeof DialogPrimitive>, DialogProps>(({ alert = false, side = 'center', className, ...props }, forwardedRef) => {
	return (
		<ModalOverlay isDismissable={alert ? false : true} className={`modal-overlay ${side}`}>
			<Modal className={`modal ${side}`}>
				<DialogPrimitive ref={forwardedRef} role={alert ? 'alertdialog' : 'dialog'} className={cn('dialog', className)} {...props} />
			</Modal>
		</ModalOverlay>
	)
})
Dialog.displayName = 'Dialog'

const DialogTitle = forwardRef<ElementRef<typeof H2>, ComponentPropsWithoutRef<typeof H2>>((props, ref) => {
	;[props, ref] = useContextProps(props, ref, HeadingContext)
	return <H2 ref={ref} slot="title" {...props} />
})
DialogTitle.displayName = 'DialogTitle'

export { DialogTrigger, Dialog, DialogTitle }
